var categoriesHandler = {

    selectors: {
        subcategory: 'select#subcategories',
        category: 'select#category'
    },

    categories: [],

    subcategories: [],

    updateSubcategories: function (c) {
        var options = '';

        var options_arr = this.subcategories.filter(function(item){
            return item.category == c || item.category == 0;
        });

        options_arr.forEach(function(item){
            options += "<option value='"+ item.id +"'>"+ item.name +"</option>";
        });

        $(this.selectors.subcategory).html(options);
    },

    addCatgory: function(id, name, slug){
        this.categories.push({
            id: id,
            name: name,
            slug: slug
        });
    },

    addSubCatgory: function(id, name, slug, category){
        this.subcategories.push({
            id: id,
            name: name,
            slug: slug,
            category: category
        });
    }

};